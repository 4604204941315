import React, { useEffect } from 'react';
import { useState } from 'react';
import './Troubleshoot-styles.scss';
import '../app/styles.scss';
import { Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

type TroubleshootProps = {
  lang: 'en' | 'id';
};

type troubleShootItemType = {
  header?: string;
  title?: string;
  problem?: string;
  potentialCause?: string | string[];
  solution?: string | string[];
  isOpened?: boolean;
};

type TroubleshootType = {
  [any: string]: troubleShootItemType[];
};

const enTroubleshoots: TroubleshootType = {
  Savart: [
    {
      title: 'WARNING:',
      problem: 'Motor Overheat',
      potentialCause:
        'Potential Cause: The road contour is steep and long, the passenger load is excessive, the throttle is pulled, and the brakes are held.',
      solution: 'Solution: Reduce speed and acceleration until the battery temperature decreases.',
      isOpened: false,
    },
    {
      title: 'ERROR 202:',
      problem: 'Throttle Error - Throttle Error',
      potentialCause: 'Potential Cause: Throttle cable is broken or loose.',
      solution:
        'Solution: Check the throttle release, turn off the motor, wait 1 minute, turn it on again. If the error persists, check the throttle cable. If the error persists, take it to Planet Ban.',
      isOpened: false,
    },
    {
      title: 'ERROR 210:',
      problem: 'Motor Overheat - Motor Over Temperature',
      potentialCause: 'Potential Cause: High motor temperature.',
      solution: 'Solution: Turn off the motor, wait for the motor to cool down.',
      isOpened: false,
    },
    {
      problem: 'The electric scooter will not start or operate.',
      potentialCause: 'Potential Cause: The battery is dead or not charged.',
      solution: 'Solution: Charge the battery.',
      isOpened: false,
    },
    {
      problem: 'Unstable steering handlebars.',
      potentialCause: [
        'Incorrect tire pressure.',
        'Worn/bald tires.',
        'Damaged steering bearings.',
        'Damaged wheel bearings.',
        'Worn engine mounts that connect the engine to the chassis.',
        'Faulty suspension.',
        'Loose wheel axle.',
      ],
      solution: [
        'Solution: Inflate the tires to the recommended pressure (check the manual for recommended pressure).',
        'Solution: Replace the tires with those that meet the recommended specifications.',
        'Solution: Inspect the bearing components. Replace any worn/damaged bearings. Tighten the handlebars properly.',
        'Solution: Inspect the bearing components. Replace any worn/damaged bearings.',
        'Solution: Replace the bushings.',
        'Solution: Replace the suspension.',
        'Solution: Tighten the wheel axle.',
      ],
      isOpened: false,
    },
  ],
  Battery: [
    {
      title: 'WARNING:',
      problem: 'Battery Overheat',
      potentialCause:
        'Potential Cause: The road contour is steep and long, the passenger load is excessive, the throttle is pulled, and the brakes are held.',
      solution: 'Solution: Reduce speed and acceleration until the battery temperature decreases.',
      isOpened: false,
    },
    {
      title: 'ERROR 100:',
      problem: 'Battery Error - Battery System Failure',
      potentialCause: 'Potential Cause: BMS (Battery Management System) part Damage.',
      solution:
        'Solution: Turn off the motor, wait 2 minutes, turn it on again. If the error persists, take it to Planet Ban.',
      isOpened: false,
    },
    {
      title: 'ERROR 101:',
      problem: 'Battery Error - Battery Short Circuit',
      potentialCause: 'Potential Cause: Construction damage or stripped cables (eaten by animals).',
      solution: 'Solution: Take it to the nearest service center or Planet Ban.',
      isOpened: false,
    },
    {
      title: 'ERROR 102:',
      problem: 'Battery Error - Battery Over Discharge Current',
      potentialCause: 'Potential Cause: Extreme driving, controller damage, stripped wires (eaten by animals).',
      solution:
        'Solution: Turn off the motor, wait 2 minutes, turn it on again. If the error persists, take it to Planet Ban.',
      isOpened: false,
    },
    {
      title: 'ERROR 103:',
      problem: 'Battery Error - Battery Over Charge Current',
      potentialCause: 'Potential Cause: Broken charger.',
      solution:
        'Solution: Unplug the charger, wait for the screen to turn off, plug it back in. If the error persists, take it to Planet Ban.',
      isOpened: false,
    },
    {
      title: 'ERROR 104:',
      problem: 'Battery Error - Battery Over Voltage',
      potentialCause: 'Potential Cause: Broken charger.',
      solution: 'Solution: charger broken while charging.',
      isOpened: false,
    },
    {
      title: 'ERROR 105:',
      problem: 'Battery Error - Battery Under Voltage',
      potentialCause: 'Potential Cause: Decreased battery health.',
      solution: 'Solution: Turn off the motor, charge as usual. If the error persists, take it to Planet Ban.',
      isOpened: false,
    },
    {
      title: 'ERROR 106:',
      problem: 'Battery Overheat - Battery Over Temperature',
      potentialCause: 'Potential Cause: Hot battery cell.',
      solution: 'Solution: Turn off the motor, wait for it to cool down.',
      isOpened: false,
    },
    {
      title: 'ERROR 108:',
      problem: 'Battery Error - Battery Unbalance',
      potentialCause: 'Potential Cause: Different cell voltages.',
      solution:
        'Solution: Turn off the motor, wait 2 minutes, turn it on again. If the error persists, take it to Planet Ban.',
      isOpened: false,
    },
  ],
  'IoT Connection': [
    {
      title: 'WARNING:',
      problem: 'Controller Overheat',
      potentialCause:
        'Potential Cause: The road contour is steep and long, the passenger load is excessive, the throttle is pulled, and the brakes are held.',
      solution: 'Solution: Reduce speed and acceleration until the battery temperature decreases.',
      isOpened: false,
    },
    {
      title: 'ERROR 107:',
      problem: 'Controller Overheat - Battery Under Temperature',
      potentialCause: 'Potential Cause: The ambient temperature is too low.',
      solution:
        'Solution: Turn off the bike, move to a warm area, try starting the bike. If the error persists, take it to Planet Ban.',
      isOpened: false,
    },
    {
      title: 'ERROR 200:',
      problem: 'Controller Error - Moto Error',
      potentialCause: 'Potential Cause: Motor is rarely cleaned, gear oil is changed late.',
      solution:
        'Solution: Turn off the motor, wait 1 minute, turn it on again. Check the power cable, connect it if it is loose, try to start again. If the error persists, take it to Planet Ban.',
      isOpened: false,
    },
    {
      title: 'ERROR 201:',
      problem: 'Controller Error - Hall Error',
      potentialCause: 'Potential Cause: Motor sensor cable disconnected or broken.',
      solution:
        'Solution: Turn off the motor, wait 1 minute, turn it on again. Check the motor cable to the controller, if it is disconnected, connect it. If the error persists, take it to Planet Ban.',
      isOpened: false,
    },
    {
      title: 'ERROR 203:',
      problem: 'Controller Error - Controller Error',
      potentialCause: 'Potential Cause: Broken controller.',
      solution: 'Solution: Turn off the motor, wait 1 minute, turn it on again. If error, take it to Planet Ban.',
      isOpened: false,
    },
    {
      title: 'ERROR 204:',
      problem: 'Controller Error - Brake Error',
      potentialCause: 'Potential Cause: Controller damaged or system wiring harness broken/detached.',
      solution:
        'Solution: Turn off the motor, wait 1 minute, turn it on again. If the error persists, take it to Planet Ban.',
      isOpened: false,
    },
    {
      title: 'ERROR 206:',
      problem: 'Controller Error - Controller Over Current',
      potentialCause: 'Potential Cause: Broken controller.',
      solution:
        'Solution: Turn off the motor, wait 1 minute, turn it on again. If the error persists, take it to Planet Ban.',
      isOpened: false,
    },
    {
      title: 'ERROR 207:',
      problem: 'Controller Error - Controller Over Voltage',
      potentialCause: 'Potential Cause: Broken controller.',
      solution:
        'Solution: Turn off the motor, wait 1 minute, turn it on again. If the error persists, take it to Planet Ban.',
      isOpened: false,
    },
    {
      title: 'ERROR 208:',
      problem: 'Controller Error - Controller Under Voltage',
      potentialCause: 'Potential Cause: Broken controller.',
      solution:
        'Solution: Make sure the battery has power, turn off the motor, wait 1 minute, turn it on again. If the error persists, take it to Planet Ban.',
      isOpened: false,
    },
    {
      title: 'ERROR 209:',
      problem: 'Controller Overheat - Controller Over Temperature',
      potentialCause: 'Potential Cause: High controller temperature.',
      solution: 'Solution: Turn off the motor, wait for the controller to cool down.',
      isOpened: false,
    },
  ],
  'Portable Charger': [
    {
      title: 'ERROR 300:',
      problem: 'Charger Error - Lamehome Bit',
      potentialCause: 'Potential Cause: Damaged charger/loose connector.',
      solution:
        'Solution: Unplug the charger, wait for the screen to turn off, plug it back in. If the error persists after 3 tries, take it to Planet Ban.',
      isOpened: false,
    },
    {
      title: 'ERROR 400:',
      problem: 'Communication Error - Lamehome Bit',
      potentialCause: 'Potential Cause: The connector is not well connected.',
      solution: 'Solution: Turn off SAVART wait 1 minute, turn on again. If error, take it to Planet Ban.',
      isOpened: false,
    },
    {
      problem: 'The Charger cannot charge the battery.',
      potentialCause: ['No voltage at the power source outlet.', 'The charger is damaged.', 'The battery is too hot.'],
      solution: [
        'Solution: Check the voltage at the power source outlet.',
        'Solution: Bring the charger to After-Sales Service.',
        'Solution: Allow the battery to cool down.',
      ],
      isOpened: false,
    },
    {
      header: 'Colored Portable Charger LED Indicator',
    },
    {
      problem: 'Red light flashes once',
      potentialCause: 'Potential Cause: Damaged charger, battery is not original SAVART.',
      solution:
        'Solution: Unplug the charger, wait for the screen to turn off, plug it back in. If 2 attempts fail, take it to Planet Ban.',
      isOpened: false,
    },
    {
      problem: 'Red light flashes twice',
      potentialCause: 'Potential Cause: Damaged charger.',
      solution:
        'Solution: Unplug the charger, wait for the screen to turn off, plug it back in. If 1 attempt fails, take it to Planet Ban.',
      isOpened: false,
    },
    {
      problem: 'Red light flashes three times',
      potentialCause: 'Potential Cause: Damaged charger, non-original SAVART battery, or wiring modification.',
      solution:
        'Solution: Unplug the charger, wait for the screen to turn off, plug it back in. If 1 attempt fails, take it to Planet Ban.',
      isOpened: false,
    },
    {
      problem: 'Flashing red and green lights',
      potentialCause: 'Potential Cause: The connector is not connected properly.',
      solution:
        "Solution: Unplug the charger, wait for the screen to turn off, plug it back in, make sure it's connected. If 1 attempt fails, take it to Planet Ban.",
      isOpened: false,
    },
    {
      title: 'Apps Notification:',
      problem: 'No Battery Detected or Polarity Reversed',
      potentialCause: 'Potential Cause: Battery connector not connected or wiring modification.',
      solution:
        'Solution: Unplug the charger, wait for the screen to turn off, plug it back in. If 2 attempts fail, take it to Planet Ban.',
      isOpened: false,
    },
    {
      title: 'Apps Notification:',
      problem: 'Input Under Voltage',
      potentialCause: 'Potential Cause: There is no PLN power source or the socket has a problem.',
      solution:
        'Solution: Unplug the charger, wait for the screen to turn off, plug it back in. If 2 attempts fail, take it to Planet Ban.',
      isOpened: false,
    },
  ],
};

const idTroubleshoots: TroubleshootType = {
  Savart: [
    {
      title: 'WARNING:',
      problem: 'Motor Overheat',
      potentialCause:
        'Potensi Penyebab: Kontur jalanan naik panjang, beban penumpang berlebihan, gas ditarik, rem ditahan',
      solution: 'Solution: Kurangi kecepatan dan akselerasi hingga suhu baterai turun',
      isOpened: false,
    },
    {
      title: 'ERROR 202:',
      problem: 'Throttle Error - Throttle Error',
      potentialCause: 'Potensi Penyebab: Kabel throttle putus atau lepas.',
      solution:
        'Solusi: Pastikan throttle release, matikan motor, tunggu 1 menit, hidupkan kembali. Jika masih error, cek kabel throttle. Jika error berlanjut, bawa ke Planet Ban.',
      isOpened: false,
    },
    {
      title: 'ERROR 210:',
      problem: 'Motor Overheat - Motor Over Temperature',
      potentialCause: 'Potensi Penyebab: Suhu motor tinggi.',
      solution: 'Solusi: Matikan motor, tunggu hingga motor dingin.',
      isOpened: false,
    },
    {
      problem: 'Skuter listrik tidak dapat hidup atau beroperasi',
      potentialCause: 'Potensi Penyebab: Baterai habis, tidak terisi daya.',
      solution: 'Solusi: Lakukan pengisian daya baterai.',
      isOpened: false,
    },
    {
      problem: 'Stang kemudi tidak stabil',
      potentialCause: [
        'Tekanan angin ban tidak sesuai.',
        'Ban telah using/gundul.',
        'Bearing kemudi mengalami kerusakan.',
        'Bearing velg/roda mengalami kerusakan.',
        'Engine mounting yang menjadi penyambung mesin dan chassis sudah aus.',
        'Suspensi mati.',
        'As roda longgar.',
      ],
      solution: [
        'Solusi: Lakukan pengisian angin ban sesuai rekomendasi pabrik (cek rekomendasi tekanan pada buku manual).',
        'Solusi: Lakukan penggantian ban sesuai dengan spesifikasi yang dianjurkan.',
        'Solusi: Periksa komponen bearing. Ganti komponen yang sudah aus/rusak. Kencangkan stang kemudi dengan baik.',
        'Solusi: Periksa komponen bearing. Ganti komponen yang sudah aus/rusak.',
        'Solusi: Lakukan penggantian busing.',
        'Solusi: Lakukan penggantian suspensi.',
        'Solusi: kencangkan as roda.',
      ],
      isOpened: false,
    },
  ],
  Baterai: [
    {
      title: 'WARNING:',
      problem: 'Battery Overheat',
      potentialCause:
        'Potensi Penyebab: Kontur jalanan naik panjang, beban penumpang berlebihan, gas ditarik, rem ditahan',
      solution: 'Solusi: Kurangi kecepatan dan akselerasi hingga suhu baterai turun',
      isOpened: false,
    },
    {
      title: 'ERROR 100:',
      problem: 'Battery Error - Battery System Failure',
      potentialCause: 'Potensi Penyebab: Kerusakan part BMS (Battery Management System).',
      solution: 'Solusi: Matikan motor, tunggu 2 menit, hidupkan kembali. Jika error berlanjut, bawa ke Planet Ban.',
      isOpened: false,
    },
    {
      title: 'ERROR 101:',
      problem: 'Battery Error - Battery Short Circuit',
      potentialCause: 'Potensi Penyebab: Kerusakan konstruksi atau kabel terkelupas (dimakan hewan).',
      solution: 'Solusi: Bawa ke service center atau Planet Ban terdekat.',
      isOpened: false,
    },
    {
      title: 'ERROR 102:',
      problem: 'Battery Error - Battery Over Discharge Current',
      potentialCause: 'Potensi Penyebab: Berkendara ekstrim, kerusakan kontroller, kabel terkelupas (dimakan hewan).',
      solution: 'Solusi: Matikan motor, tunggu 2 menit, hidupkan kembali. Jika error berlanjut, bawa ke Planet Ban.',
      isOpened: false,
    },
    {
      title: 'ERROR 103:',
      problem: 'Battery Error - Battery Over Charge Current',
      potentialCause: 'Potensi Penyebab: Charger rusak.',
      solution: 'Solusi: Cabut charger, tunggu layar mati, colokkan kembali. Jika error berlanjut, bawa ke Planet Ban.',
      isOpened: false,
    },
    {
      title: 'ERROR 104:',
      problem: 'Battery Error - Battery Over Voltage',
      potentialCause: 'Potensi Penyebab: Charger rusak saat charging.',
      solution: 'Solusi: Cabut charger, tunggu layar mati, nyalakan motor. Jika error berlanjut, bawa ke Planet Ban.',
      isOpened: false,
    },
    {
      title: 'ERROR 105:',
      problem: 'Battery Error - Battery Under Voltage',
      potentialCause: 'Potensi Penyebab: Kesehatan baterai menurun.',
      solution: 'Solusi: Matikan motor, charge seperti biasa. Jika error berlanjut, bawa ke Planet Ban.',
      isOpened: false,
    },
    {
      title: 'ERROR 106:',
      problem: 'Battery Overheat - Battery Over Temperature',
      potentialCause: 'Potensi Penyebab: Cell baterai panas.',
      solution: 'Solusi: Matikan motor, tunggu sampai dingin.',
      isOpened: false,
    },
    {
      title: 'ERROR 108:',
      problem: 'Battery Error - Battery Unbalance',
      potentialCause: 'Potensi Penyebab: Tegangan cell berbeda.',
      solution: 'Solusi: Matikan motor, tunggu 2 menit, hidupkan kembali. Jika error berlanjut, bawa ke Planet Ban.',
      isOpened: false,
    },
  ],
  'Koneksi IoT': [
    {
      title: 'WARNING:',
      problem: 'Controller Overheat',
      potentialCause:
        'Potensi Penyebab: Kontur jalanan naik panjang, beban penumpang berlebihan, gas ditarik, rem ditahan',
      solution: 'Solution: Kurangi kecepatan dan akselerasi hingga suhu baterai turun',
      isOpened: false,
    },
    {
      title: 'ERROR 107:',
      problem: 'Controller Overheat - Battery Under Temperature',
      potentialCause: 'Potensi Penyebab: Suhu lingkugan terlalu rendah.',
      solution:
        'Solusi: Matikan motor, pindah ke area hangat, coba hidupkan motor. Jika error berlanjut, bawa ke Planet Ban.',
      isOpened: false,
    },
    {
      title: 'ERROR 200:',
      problem: 'Controller Error - Moto Error',
      potentialCause: 'Potensi Penyebab: Motor jarang dibersihkan, oli gear terlambat diganti.',
      solution:
        'Solusi: Matikan motor, tunggu 1 menit, hidupkan kembali. Cek kabel power, sambungkan jika lepas, coba nyalakan lagi. Jika error berlanjut, bawa ke Planet Ban.',
      isOpened: false,
    },
    {
      title: 'ERROR 201:',
      problem: 'Controller Error - Hall Error',
      potentialCause: 'Potensi Penyebab: Kabel sensor motor terlepas atau putus.',
      solution:
        'Solusi: Matikan motor, tunggu 1 menit, hidupkan kembali. Cek kabel motor ke controller, jika terlepas, sambungkan. Jika error berlanjut, bawa ke Planet Ban.',
      isOpened: false,
    },
    {
      title: 'ERROR 203:',
      problem: 'Controller Error - Controller Error',
      potentialCause: 'Potensi Penyebab: Controller rusak.',
      solution: 'Solusi: Matikan motor, tunggu 1 menit, hidupkan kembali. Jika error, bawa ke Planet Ban.',
      isOpened: false,
    },
    {
      title: 'ERROR 204:',
      problem: 'Controller Error - Brake Error',
      potentialCause: 'Potensi Penyebab: Controller rusak atau kabel sistem harness putus/terlepas.',
      solution: 'Solusi: Matikan motor, tunggu 1 menit, hidupkan kembali. Jika error berlanjut, bawa ke Planet Ban.',
      isOpened: false,
    },
    {
      title: 'ERROR 206:',
      problem: 'Controller Error - Controller Over Current',
      potentialCause: 'Potensi Penyebab: Controller rusak.',
      solution: 'Solusi: Matikan motor, tunggu 1 menit, hidupkan kembali. Jika error berlanjut, bawa ke Planet Ban.',
      isOpened: false,
    },
    {
      title: 'ERROR 207:',
      problem: 'Controller Error - Controller Over Voltage',
      potentialCause: 'Potensi Penyebab: Controller rusak.',
      solution: 'Solusi: Matikan motor, tunggu 1 menit, hidupkan kembali. Jika error berlanjut, bawa ke Planet Ban.',
      isOpened: false,
    },
    {
      title: 'ERROR 208:',
      problem: 'Controller Error - Controller Under Voltage',
      potentialCause: 'Potensi Penyebab: Controller rusak.',
      solution:
        'Solusi: Pastikan baterai memiliki daya, matikan motor, tunggu 1 menit, hidupkan kembali. Jika error berlanjut, bawa ke Planet Ban.',
      isOpened: false,
    },
    {
      title: 'ERROR 209:',
      problem: 'Controller Overheat - Controller Over Temperature',
      potentialCause: 'Potensi Penyebab: Suhu controller tinggi.',
      solution: 'Solusi: Matikan motor, tunggu hingga controller dingin.',
      isOpened: false,
    },
  ],
  'Portable Charger': [
    {
      title: 'ERROR 300:',
      problem: 'Charger Error - Lamehome Bit',
      potentialCause: 'Potensi Penyebab: Charger rusak/konektor longgar.',
      solution:
        'Solusi: Cabut charger, tunggu layar mati, colokkan kembali. Jika error berlanjut setelah 3x percobaan, bawa ke Planet Ban.',
      isOpened: false,
    },
    {
      title: 'ERROR 400:',
      problem: 'Communication Error - Lamehome Bit',
      potentialCause: 'Potensi Penyebab: Konektor tidak terhubung baik.',
      solution: 'Solusi: Matikan SAVART tunggu 1 menit, hidupkan kembali. Jika error, bawa ke Planet Ban.',
      isOpened: false,
    },
    {
      problem: 'Charger tidak dapat mengisi baterai',
      potentialCause: [
        'Tidak ada tegangan pada stop kontak sumber listrik.',
        'Charger dalam kondisi rusak.',
        'Baterai terlalu panas.',
      ],
      solution: [
        'Solusi: Cek tegangan stop kontak sumber listrik.',
        'Solusi: bawa charger ke Layanan Purna Jual',
        'Solusi: Diamkan baterai sampai dingin.',
      ],
      isOpened: false,
    },
    {
      header: 'Indikator LED Portable Charger Berwarna',
    },
    {
      problem: 'Lampu merah berkedip sekali',
      potentialCause: 'Potensi Penyebab: Charger rusak, baterai tidak original SAVART.',
      solution: 'Solusi: Cabut charger, tunggu layar mati, colok kembali. Jika 2x percobaan gagal, bawa ke Planet Ban.',
      isOpened: false,
    },
    {
      problem: 'Lampu merah berkedip dua kali',
      potentialCause: 'Potensi Penyebab: Charger rusak.',
      solution: 'Solusi: Cabut charger, tunggu layar mati, colok kembali. Jika 1x percobaan gagal, bawa ke Planet Ban.',
      isOpened: false,
    },
    {
      problem: 'Lampu merah berkedip tiga kali',
      potentialCause: 'Potensi Penyebab: Charger rusak, baterai tidak original SAVART, atau modifikasi pengkabelan.',
      solution: 'Solusi: Cabut charger, tunggu layar mati, colok kembali. Jika 1x percobaan gagal, bawa ke Planet Ban.',
      isOpened: false,
    },
    {
      problem: 'Lampu merah dan hijau berkedip',
      potentialCause: 'Potensi Penyebab: Konektor tidak terhubung dengan baik.',
      solution:
        'Solusi: Cabut charger, tunggu layar mati, colok kembali, pastikan terhubung. Jika 1x percobaan gagal, bawa ke Planet Ban.',
      isOpened: false,
    },
    {
      title: 'Apps Notification:',
      problem: 'No Battery Detected or Polarity Reversed',
      potentialCause: 'Potensi Penyebab: Konektor baterai tidak terhubung atau modifikasi pengkabelan.',
      solution: 'Solusi: Cabut charger, tunggu layar mati, colok kembali. Jika 2x percobaan gagal, bawa ke Planet Ban.',
      isOpened: false,
    },
    {
      title: 'Apps Notification:',
      problem: 'Input Under Voltage',
      potentialCause: 'Potensi Penyebab: Sumber listrik PLN tidak ada atau stop kontak bermasalah.',
      solution: 'Solusi: Cabut charger, tunggu layar mati, colok kembali. Jika 2x percobaan gagal, bawa ke Planet Ban.',
      isOpened: false,
    },
  ],
};

export default function Troubleshoot({ lang }: TroubleshootProps) {
  const [troubleshoots, setTroubleshoots] = useState<TroubleshootType>(
    lang === 'id' ? idTroubleshoots : enTroubleshoots
  );
  const [tablist, setTablist] = useState<string[]>([]);
  const [selectedTablistIndex, setSelectedTablistIndex] = useState(0);

  const onClickOpen = (key: string, index: number) => {
    setTroubleshoots({
      ...troubleshoots,
      [key]: troubleshoots[key].map((item, idx) => (idx === index ? { ...item, isOpened: !item.isOpened } : item)),
    });
  };

  const onClickCapsule = (index: number) => {
    setSelectedTablistIndex(index);
  };

  useEffect(() => {
    const keys = Object.keys(troubleshoots);
    setTablist(keys);
  }, [troubleshoots]);

  return (
    <main className="troubleshoot-page">
      <div className="tab-bar">
        {tablist?.map((name: string, index: number) => (
          <div
            key={index}
            className={`capsule ${selectedTablistIndex === index ? 'selected' : ''}`}
            onClick={() => onClickCapsule(index)}
          >
            {name}
          </div>
        ))}
      </div>

      {troubleshoots[tablist[selectedTablistIndex]]?.map((item, index) => {
        return (
          <section className="troubleshoot-section" key={index}>
            {item?.header ? (
              <div className="title">{item.header}</div>
            ) : (
              <div className="troubleshoot-item">
                <div className="upper-content" onClick={() => onClickOpen(tablist[selectedTablistIndex], index)}>
                  <div>
                    <div className="subtitle">{item?.title}</div>
                    <div className="subtitle">{item.problem}</div>
                  </div>
                  <div className={'icon' + (item.isOpened ? ' icon-open' : '')}>
                    <FontAwesomeIcon icon={faAngleDown} />
                  </div>
                </div>
                <Collapse in={item.isOpened}>
                  <div>
                    {typeof item?.potentialCause === 'string' && typeof item?.solution === 'string' && (
                      <>
                        <p className="paragraph">{item?.potentialCause}</p>
                        <p className="paragraph">{item?.solution}</p>
                      </>
                    )}
                    {typeof item?.potentialCause === 'object' && typeof item?.solution === 'object' && (
                      <>
                        <p className="paragraph">{lang === 'en' ? 'Potential Cause:' : 'Potensi Penyebab:'}</p>
                        {item?.potentialCause?.map((element, index) => (
                          <React.Fragment key={index}>
                            <div className="potcause-container">
                              <div className="left-content">
                                <p className="paragraph">{String.fromCharCode(97 + index) + ')'}</p>
                              </div>
                              <p className="paragraph">{element}</p>
                            </div>
                            <div className="potcause-container">
                              <div className="left-content"></div>
                              <p className="paragraph">{item?.solution?.[index]}</p>
                            </div>
                          </React.Fragment>
                        ))}
                      </>
                    )}
                  </div>
                </Collapse>
                <hr style={{ opacity: 0.1 }} />
              </div>
            )}
          </section>
        );
      })}
    </main>
  );
}
